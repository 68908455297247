<template>
  <div>
    <trac-loading v-if="loading" />
    <div v-else>
      <div
        class="single-category small-shadow bg-white rounded-lg card-gradient cursor-pointer py-6 px-5 mb-12"
      >
        <h1 class="text-2xl font-semibold mb-6">{{ category.name }}</h1>
        <p>{{ category.category_products.product_count }} Products</p>
      </div>

      <category-details :category="category" />

      <div>
        <sub-categories-list :category="category" @close="fetchCategory" />

        <category-items
          v-model="searchedItem"
          :products="category.category_products.products"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import CategoryInfo from "./CategoryInfo.vue";
import SubCategoriesList from "./SubCategoriesList.vue";
import CategoryDetails from "./CategoryDetails.vue";
import CategoryItems from "./CategoryItems.vue";

export default {
  components: {
    SubCategoriesList,
    CategoryInfo,
    CategoryDetails,
    CategoryItems,
  },
  name: "Category",
  data() {
    return {
      loading: false,
      searchedItem: "",
    };
  },
  created() {
    this.fetchCategory();
  },
  computed: {
    ...mapGetters({
      category: "GET_PRODUCT_CATEGORY",
    }),
  },
  methods: {
    ...mapActions({
      FETCH_PRODUCTS_CATEGORY: "FETCH_PRODUCTS_CATEGORY",
    }),
    async fetchCategory() {
      this.loading = true;
      try {
        await this.FETCH_PRODUCTS_CATEGORY(this.$route.params?.id);
      } catch (error) {
        return eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
