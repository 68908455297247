var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-between items-center"},[_c('h1',{staticClass:"text-2xl font-semibold mb-8"},[_vm._v(" Subcategories ("+_vm._s(_vm.category.sub_categories ? _vm.category.sub_categories.length : 0)+") ")]),_c('create-sub-category',{attrs:{"category":_vm.category,"name-disabled":""},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"default",fn:function({ toggleModal }){return [_c('trac-button',{staticClass:"uppercase text-xs py-1",on:{"button-clicked":toggleModal}},[_vm._v(" New Subcategory ")])]}}])})],1),(
      _vm.category.sub_category_products
        ? _vm.category.sub_category_products.length
        : 0
    )?_c('div',{staticClass:"grid grid-cols-12 gap-6"},_vm._l((_vm.category.sub_category_products),function(sub_category){return _c('category-info',{key:sub_category.id,staticClass:"col-span-3",attrs:{"category":sub_category},nativeOn:{"click":function($event){return _vm.$router.push({
          path: `/productsinventory/categories/${_vm.$route.params.id}/${sub_category.id}`,
        })}}})}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }