<template>
  <div>
    <div
      class="small-shadow bg-white rounded-lg card-gradient mt-10 py-12 px-8"
    >
      <div class="flex justify-between items-center mb-6">
        <h3 class="font-semibold">Your Items</h3>
        <!-- <div class="small-shadow w-1/3">
          <trac-input
            v-model="searchValue"
            :inputProps="{
              class: 'border-transparent text-blue',
            }"
            placeholder="Search for an item to add"
          >
            <template #icon>
              <button class="relative" style="left: 20px; top: -5px">
                <img
                  src="@/assets/svg/search-icon-black.svg"
                  alt="search-icon"
                />
              </button>
            </template>
          </trac-input>
        </div> -->
      </div>

      <div class="grid grid-cols-5 gap-5">
        <div
          v-for="product in filteredProducts"
          :key="product.id"
          class="col-span-1 p-3 border border-[#ECECEC] rounded-md"
        >
          <div
            class="flex justify-center border-b border-b-[#ECECEC] w-full"
            style="height: 147px"
          >
            <img
              :src="
                product.image || require('@/assets/images/default-image.png')
              "
              :alt="product.title"
              class="w-full h-full object-cover"
            />
          </div>
          <p class="text-center py-2">{{ product.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryItems",
  emits: ["input"],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      searchValue: "",
    };
  },
  computed: {
    filteredProducts() {
      return this.products?.filter((prod) =>
        prod.title.toLowerCase().match(this.searchValue.toLowerCase())
      );
    },
  },
  watch: {
    searchValue(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
