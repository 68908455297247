<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="text-2xl font-semibold mb-8">
        Subcategories ({{
          category.sub_categories ? category.sub_categories.length : 0
        }})
      </h1>
      <create-sub-category
        :category="category"
        name-disabled
        @close="$emit('close')"
      >
        <template #default="{ toggleModal }">
          <trac-button
            class="uppercase text-xs py-1"
            @button-clicked="toggleModal"
          >
            New Subcategory
          </trac-button>
        </template>
      </create-sub-category>
    </div>

    <div
      v-if="
        category.sub_category_products
          ? category.sub_category_products.length
          : 0
      "
      class="grid grid-cols-12 gap-6"
    >
      <category-info
        v-for="sub_category in category.sub_category_products"
        :key="sub_category.id"
        class="col-span-3"
        :category="sub_category"
        @click.native="
          $router.push({
            path: `/productsinventory/categories/${$route.params.id}/${sub_category.id}`,
          })
        "
      />
    </div>
  </div>
</template>

<script>
import CategoryInfo from "./CategoryInfo.vue";
import CreateSubCategory from "./CreateSubCategory.vue";

export default {
  components: { CategoryInfo, CreateSubCategory },
  emits: ["close"],
  name: "SubCategoriesList",
  props: {
    category: {
      type: Object,
      default: () => ({
        subCategories: [],
      }),
    },
  },
};
</script>

<style lang="scss" scoped></style>
