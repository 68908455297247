<template>
  <div>
    <slot name="default" :toggle-modal="toggleModal" />

    <trac-loading v-if="loading" />
    <trac-modal
      v-else-if="showModal && !loading"
      @close="
        () => {
          toggleModal();
          formData.name = '';
          formData.category_id = '';
          formData.product_ids = [];
        }
      "
    >
      <h1 class="mt-8 mb-12 text-xl font-bold text-accentDark">
        Create Subcategory
      </h1>

      <div>

          <form
            @submit.prevent="reuploadFailedProduct"
            class="flex flex-col gap-6"
          >
            <div>
              <trac-input
                v-if="nameDisabled"
                :value="category.name"
                placeholder="Category"
                :disabled="nameDisabled"
              />
              <trac-dropdown-exteneded
                v-else
                :neededProperty="'name'"
                selector="Category"
                :options="allCategories"
                @optionSelected="updateCategory"
              ></trac-dropdown-exteneded>
            </div>

            <div>
              <trac-input
                v-model="formData.name"
                type="text"
                placeholder="Subcategory"
              />
              <trac-input-error v-if="error.name">
                {{ error.name }}
              </trac-input-error>
            </div>

            <!-- <div>
              <trac-multi-select-dropdown
                @input="selectProduct"
                title="Select Product(s)"
                :items="
                  nameDisabled
                    ? category.category_products.products ||
                      category.products ||
                      []
                    : categoryProducts
                "
                displayProp="title"
                :allow-initial-load="false"
                :reset="resetCategoryDropdown"
              ></trac-multi-select-dropdown>
            </div> -->

            <div class="flex justify-center mt-20">
              <trac-button
                class="uppercase text-xs"
                :disabled="!formData.name"
                @button-clicked="submit"
              >
                Create Subcategory
              </trac-button>
            </div>
          </form>
        </trac-validation-observer>
      </div>
    </trac-modal>

    <trac-success-modal v-if="showSuccessModal" @close="close">
      <p>Subcategory has been created successfully.</p>
    </trac-success-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "../../eventBus";

export default {
  name: "CreateSubCategory",
  emits: ["close"],
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    nameDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      showSuccessModal: false,
      formData: {},
      loading: false,
      categoryProducts: [],
      resetCategoryDropdown: false,
      error: {}
    };
  },
  created() {
    if (!this.nameDisabled) {
      this.fetchCategories();
    }
    console.log('here')
  },
  computed: {
    ...mapGetters({
      allCategories: "GET_PRODUCT_CATEGORIES",
    }),
    fields() {
      return {
        name: {
          valid: /[a-zA-Z0-9]/giu.test(this.formData?.name || ""),
        },
      };
    },
    fieldsValid() {
      return Object.values(this.fields).every(({ valid }) => !!valid);
    },
  },
  methods: {
    ...mapActions(["CREATE_PRODUCTS_SUBCATEGORY", "FETCH_PRODUCTS_CATEGORIES"]),
    async fetchCategories() {
      this.loading = true;
      try {
        await this.FETCH_PRODUCTS_CATEGORIES();
      } catch (error) {
        return eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleSuccessModal() {
      this.showSuccessModal = !this.showSuccessModal;
    },
    switchView() {
      this.toggleModal();
      this.toggleSuccessModal();
    },
    validator() {
      return Object.entries(this.fields).every(([key, { valid }]) => {
        this.error[key] = !valid;
        console.log(valid, this.error, key);
        return !!valid;
      });
    },
    async submit() {
      const isValid = this.validator();
      
      if (isValid) {
        const payload = {
          ...this.formData,
          product_ids: this.formData.product_ids?.length
            ? this.formData.product_ids
            : undefined,
          category_id: this.nameDisabled
            ? this.category.id
            : this.formData.category_id,
        };

        this.loading = true;
        try {
          const res = await this.CREATE_PRODUCTS_SUBCATEGORY(payload);
          if (res.status) {
            this.switchView();
          }
        } catch (error) {
          return eventBus.$emit("trac-alert", {
            message: error?.response?.data?.message || "Something went wrong",
          });
        } finally {
          this.loading = false;
        }
      }
    },
    updateCategory(category) {
      this.formData.category_id = category.id;
      this.categoryProducts = category.products;
      this.formData.product_ids = [];
      this.resetCategoryDropdown = !this.resetCategoryDropdown;
    },
    selectProduct(ids) {
      this.formData.product_ids = ids;
    },
    close() {
      this.toggleSuccessModal();
      this.formData.name = '';
          this.formData.category_id = '';
          this.formData.product_ids = [];
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
