<template>
  <div>
    <div
      class="small-shadow bg-white rounded-lg card-gradient mt-12 mb-10 py-12 px-8"
    >
      <trac-custom-header class="mb-3">
        <template slot="trailing"> Details</template>
      </trac-custom-header>

      <div class="grid grid-cols-12 gap-20">
        <div class="col-span-4">
          <trac-input v-model="formData.name" placeholder="Category Name" />
        </div>
        <div class="col-span-4 flex gap-5">
          <trac-button
            type="submit"
            variant="small"
            :disabled="!formData.name"
            @button-clicked="openActionModal('update')"
          >
            <div class="leading-7 font-bold">UPDATE</div>
          </trac-button>
          <trac-button
            type="submit"
            variant="danger-outline"
            @button-clicked="openActionModal('delete')"
          >
            <div class="leading-7">DELETE</div>
          </trac-button>
        </div>
      </div>
    </div>

    <trac-loading v-if="loading" />

    <trac-modal v-if="showActionModal" @close="showActionModal = false">
      <div class="mb-12">
        <div class="text-center mt-16 mb-8">
          <span>Are you sure you want to {{ modalText }} this category?</span>
        </div>

        <div class="flex justify-center">
          <div class="w-3/5">
            <trac-button
              class="uppercase text-xs w-full"
              @button-clicked="submit"
            >
              {{ modalText }}
            </trac-button>
          </div>
        </div>
      </div>
    </trac-modal>

    <trac-success-modal v-if="showSuccessModal" @close="close">
      <span>Category has been {{ modalText }}d successfully.</span>
    </trac-success-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  name: "CategoryDetails",
  props: {
    category: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      modalText: "",
      showActionModal: false,
      showSuccessModal: false,
      formData: {},
      loading: false,
    };
  },
  computed: {
    categoryId() {
      return this.$route.params?.id;
    },
    isUpdate() {
      return this.modalText === "update";
    },
  },
  created() {
    if (this.category?.name) {
      this.formData.name = this.category.name;
    }
  },
  methods: {
    ...mapActions([
      "EDIT_PRODUCTS_CATEGORY",
      "FETCH_PRODUCTS_CATEGORY",
      "DELETE_PRODUCTS_CATEGORY",
    ]),
    async submit() {
      this.loading = true;

      const mutateFn = this.isUpdate
        ? "EDIT_PRODUCTS_CATEGORY"
        : "DELETE_PRODUCTS_CATEGORY";
      const payload = this.isUpdate
        ? {
            ...this.formData,
            id: this.categoryId,
          }
        : this.categoryId;

      try {
        const res = await this[mutateFn](payload);

        if (res.status) {
          this.toggleSuccess();
        }
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    openActionModal(value) {
      this.modalText = value;
      this.showActionModal = true;
    },
    toggleSuccess() {
      this.showActionModal = false;
      this.showSuccessModal = !this.showSuccessModal;
      this.isUpdate && this.FETCH_PRODUCTS_CATEGORY(this.categoryId);
    },
    close() {
      if (!this.isUpdate) {
        this.$router.push({
          path: "/productsinventory/categories",
        });

        return;
      }

      this.toggleSuccess();
    },
  },
};
</script>

<style lang="scss" scoped></style>
